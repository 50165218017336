.container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.head2 {
    text-align: center;
    margin-bottom: 20px;
}

.forma {
    display: flex;
    flex-direction: column;
}

.labels {
    margin-bottom: 8px;
    font-weight: bold;
}

.inputs {
    padding: 10px;
/*    margin-bottom: 15px; */
    border: 1px solid #ddd;
    border-radius: 5px;
}

button {
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #45a049;
}

.subscriptionScreen {
    text-align: center;
}

.subscriptionScreen p {
    margin-bottom: 20px;
    font-size: 18px;
}

#paymentScreen {
    display: block;
}

#subscriptionScreen {
    display: none;
}

/**/
.sectionTitle{
    font-size: 20px;
    color: #444;
    margin-bottom: 20px;
}

.inputGroup {
    margin-bottom: 15px;
}

  .inputGroup label {
    display: block;
    font-size: 16px;
    color: #555;
  }

  .cardElementContainer {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 6px;
    border: 1px solid #ddd;
  }

  .cardElement {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
  }

  .submitbtn {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-top: 20px;
  }

  .submitbtn:hover {
    background-color: #45a049;
  }

  .errormessage {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }

  .successmessage {
    color: green;
    font-size: 16px;
    margin-top: 10px;
  }

  .cardinfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }

  .cardinfo p {
    font-size: 16px;
    color: #555;
  }

  .editbtn {
    background-color: #f0ad4e;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

  .editbtn:hover {
    background-color: #ec971f;
  }


  .con{
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: flex-start;
  }