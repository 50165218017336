/* Estilos generales */
.configPage {
  margin-top: 20px;
  display: flex;
  max-width: 1000px;
  /* margin: 0 auto;*/
  background-color: #f4f4f9;
  /*  padding: 20px;*/
  border-radius: 8px;
}

/* Sidebar */
.sidebar {
  width: 250px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  color: #1c2434;
}

.sidebar h2 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.sidebar ul li.active,
.sidebar ul li:hover {
  background-color: #333;
  color: #fff;
}
.active {
  background-color: #333;
  color: #fff;
}
/* Contenido de cada sección */
.content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-left: 20px;
}

.content h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #1c2434;
}

label {
  display: block;
  font-weight: bold;
  margin-top: 15px;
  color: #666;
}

input[type="text"],
input[type="email"],
select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
