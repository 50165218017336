.headerBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1c2434;
  color: white;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin: 20px 0px;
}
.headerContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.saasName {
  font-size: 1.4em;
  font-weight: bold;
  letter-spacing: 1px;
}
.restaurantName {
  font-size: 1em;
  font-style: bold;
  color: #d3d6db;
}
/* Estilos de fecha y hora */
.dateTime {
  text-align: right;
  font-size: 0.9em;
  color: #c0c4cc;
}
.dateTime .date {
  display: block;
  font-weight: bold;
}
.dateTime .time {
  font-size: 1.1em;
  font-weight: bold;
  color: #42a5f5;
}
