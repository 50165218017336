body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
  min-height: 0;
}
.copy-button {
  color: #fff !important;
  background-color: transparent !important;
}
.sidebar {
  height: 100vh;
  width: 250px;
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 5; /* Un valor más bajo para no interferir con otros elementos */
}

.sidebar h2 {
  text-align: center;
  font-size: 22px;
  color: #fff;
  margin-bottom: 30px;
}

.sidebar a {
  /*  padding: 15px 20px; */
  text-decoration: none;
  font-size: 18px;
  color: #ccc;
  display: flex;
  align-items: center;
  transition:
    background 0.3s,
    color 0.3s;
  padding: 8px 16px;
  margin: 4px 8px;
  border-radius: 4px;
}

.sidebar a i {
  margin-right: 10px;
  font-size: 20px;
}

.sidebar a:hover {
  background-color: #fff;
  color: #000000;
}

.sidebar a.active {
  background-color: #ffffff1a;
  color: #fff;
}

.hamburger {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    position: fixed;
    z-index: 1;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .hamburger {
    display: block;
    cursor: pointer;
    position: fixed;
    top: 5px;
    left: 15px;
    z-index: 100;
    font-size: 24px;
    border: none;
    color: #333;
  }
}

.main-content {
  margin-top: 50px;
  margin-left: 250px;
  padding: 20px;
  transition: margin-left 0.3s ease;
  /*  width: calc(100% - 247px); */
}

.dish-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  /*  padding: 20px; */
}

hr.solid {
  border-top: 3px solid #bbb;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    width: 100%;
  }
}

.topbar {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: fixed;
  width: calc(100% - 249px);
  left: 250px;
  top: 0;
  z-index: 10; /* Asegura que tenga un z-index más bajo que el dropdown */
  transition: left 0.3s ease;
}

@media (max-width: 768px) {
  .topbar {
    width: 100%;
    left: 0;
  }
}

@media (max-width: 468px) {
  .topbar {
    width: 94%;
    left: 0;
  }
}

.topbar .restaurant-info {
  font-size: 18px;
}

.topbar .user-info {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.topbar .user-info span {
  margin-right: 20px;
}

.topbar button {
  background-color: white;
  color: #333;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.admin-panel {
  background-color: white;
  padding: 20px;
  margin-top: 80px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #333;
}

form {
  display: grid;
  gap: 15px;
}

label {
  font-weight: bold;
}

input,
textarea,
select {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 12px;
  font-size: 16px;
  color: white;
  background-color: #f3f3f3;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
/*
button:hover {
  background-color: #F3F3F3;
}
*/
.menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.menu-item button {
  background-color: #ff3b30;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.menu-item button:hover {
  background-color: #e02020;
}

.breadcrumb {
  display: flex;
  list-style: none;
  padding: 8px 0;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.breadcrumb li {
  margin-right: 10px;
  font-size: 26px;
}

.breadcrumb li a {
  text-decoration: none;
  color: #007bff;
}

.breadcrumb li.active {
  font-weight: bold;
  color: #495057;
}

.breadcrumb li + li::before {
  /*  content: ">"; */
  margin-right: 10px;
  color: #6c757d;
}

.menu-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.menu-card {
  border: 1px solid #ddd;
  padding: 20px;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-card h3 {
  margin: 0;
  font-size: 20px;
}

.menu-card-content p {
  margin: 8px 0;
}

.delete-btn {
  background-color: #de1b72;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.add-dish-form {
  margin-top: 40px;
}

.add-dish-form input,
.add-dish-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
}

.add-dish-form button {
  background-color: #00233c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Dropdown and Button Adjustments */
.dropdown {
  position: relative;
}

.dropdown-toggle {
  background-color: #0065ff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 20; /* Asegura que el botón esté por delante */
  position: relative;
}

.dropdown-toggle i {
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 105%;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  z-index: 30; /* Asegura que el menú esté por delante de otros elementos */
  width: 150px;
}

.dropdown-menu a,
.dropdown-menu .logout-btn {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  text-align: left;
  /*  width: 100%; */
}

.dropdown-menu a:hover,
.dropdown-menu .logout-btn:hover {
  background-color: #f5f5f5;
}

.logout-btn {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
}

.loader1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* O la altura que desees */
  font-size: 24px;
  color: #333; /* Cambia el color según tu diseño */
}

.loader-overlay {
  position: fixed; /* Fijo para ocupar toda la pantalla */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center; /* Centrar el contenido verticalmente */
  z-index: 1002; /* Mayor que el modal */
}

.loader {
  font-size: 18px; /* Tamaño del texto */
  color: white; /* Color del texto del loader */
  text-align: center; /* Centrar el texto */
}

.labelText {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}

.margen {
  margin-right: 10px;
}

.copy-success {
  color: green;
  font-size: 0.9em;
  margin-left: 10px;
}
