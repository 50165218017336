/* DishCard.css */
.dish-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  /*  margin: 10px; */
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dish-card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.dish-card-content {
  padding: 15px;
}

.dish-card-content h3 {
  margin: 0;
  font-size: 18px;
}

.dish-card-content p {
  margin: 5px 0 10px;
  color: #666;
}

.dish-card-content span {
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.delete-btn {
  background-color: #de1b72;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.add-btn {
  background-color: #1c2434;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  height: 50px;
}
.add-btn:hover {
  background-color: #1c2434;
  opacity: 0.9;
}
