/* src/css/button.module.css */

.Boton {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  /*    width: 100%;  */
}

/* Color predeterminado */
.default {
  background-color: #1c2434;
  color: white;
}

/* Colores para los estados de la comanda */
.received {
  background-color: #42a5f5; /* Azul claro para Pedido Recibido */
  color: white;
}

.preparing {
  background-color: #ff9800; /* Naranja para En Preparación */
  color: white;
}

.ready {
  background-color: #2e844a; /* Verde para Listo para Recoger */
  color: white;
}

.delivered {
  background-color: #9e9e9e; /* Gris oscuro para Entregado */
  color: white;
}

.canceled {
  background-color: #f44336; /* Rojo para Cancelado */
  color: white;
}

/* Efecto hover */
.Boton:hover {
  opacity: 0.9;
}

.large {
  width: 100%;
}

.small {
  width: 85px;
}

.medium {
  width: 150px;
}

.smaller {
  width: 50px;
}
