.dayRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}
.dayLabel {
    font-weight: bold;
    width: 100px;
}
.timeSelect {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 80px;
}
.toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.toggle input {
    margin-right: 5px;
}
.saveBtn {
    display: block;
    width: 100%;
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}
.saveBtn:hover {
    background: #0056b3;
}