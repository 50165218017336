.form{
  gap: 0px;
}

.sucursales{
  display: flex;
  align-items: center;
}

 .check{
  width: -webkit-fill-available;
 }