/* alert.module.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden; /* Oculto por defecto */
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.3s ease;
}

.overlay.visible {
  visibility: visible;
  opacity: 1;
}

.alertBox {
  background-color: white;
  padding: 20px;
  width: 300px;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.title {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.message {
  margin: 15px 0;
  color: #666;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.button {
  flex: 1;
  padding: 10px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm {
  background-color: #1c2434;
  color: white;
}

.cancel {
  background-color: #ccc;
  color: #333;
}
