/* General container for the sharing section */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  /*  padding: 20px;*/
  /*  background-color: #f9f9f9;*/
  border-radius: 10px;
  /*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
}

/* Individual section divs */
.section {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 700px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

/* QR Section */
.qrSection {
  text-align: center;
}

.qrSection p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

/* Input fields and buttons container */
.inputContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.inputField {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  box-sizing: border-box;
}

.button {
  background-color: #1c2434;
  color: #fff;
  padding: 12px 25px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #333;
}

/* Textarea style for predefined message */
.textarea {
  width: 100%;
  max-width: 600px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 100px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

textarea:focus {
  outline: none;
  border-color: #1c2434;
}

/* Link container for the WhatsApp button */
.whatsappContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

/* Buttons aligned */
.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
}
