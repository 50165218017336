/*
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 20px 0px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

h2 {
  text-align: center;
  color: #333;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 12px;
  text-align: left;
}
/*
  th {
    background-color: #4CAF50;
    color: white;
  }
  */
tr:nth-child(even) {
  background-color: #f9f9f9;
}

.itemsContainer {
  max-height: 150px;
  overflow-y: auto;
}

.item {
  margin-bottom: 5px;
  padding: 5px;
  background: #e7f3e7;
  border-radius: 5px;
}

.status {
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
}

.status.Pendiente {
  background-color: #ff9800;
}

.status.Listo {
  background-color: #4caf50;
}

.status.Cancelada {
  background-color: #f44336;
}

.status.Entregado {
  background-color: #207fcc;
}

.clienteCell {
  max-width: 200px; /* Limita el ancho máximo */
  word-wrap: break-word; /* Permite que las palabras se dividan en varias líneas */
  overflow: hidden; /* Oculta cualquier contenido que exceda el ancho */
  text-overflow: ellipsis; /* Añade "..." si el texto es muy largo */
}
