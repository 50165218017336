.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.overlay.visible {
  visibility: visible;
  opacity: 1;
}

.modalBox {
  background-color: white;
  padding: 20px;
  width: 350px;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.title {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 15px 0;
}

.body input {
  padding: 8px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.confirm {
  background-color: #1c2434;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel {
  background-color: #ccc;
  color: #333;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
