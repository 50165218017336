.container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

h2 {
  color: #1c2434;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

.statusBox {
  margin: 20px 0;
  padding: 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.connected {
  color: green;
  font-weight: bold;
}

.notConnected {
  color: red;
  font-weight: bold;
}

.buttonContainer {
  text-align: center;
}

.connectButton {
  background-color: #6772e5;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.connectButton:hover {
  background-color: #5469d4;
}

.connectButton:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}

.instructions {
  margin-top: 20px;
  padding: 10px;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 6px;
  color: #8a6d3b;
  font-size: 14px;
  text-align: left;
}

.instructions strong {
  color: #1c2434;
}
