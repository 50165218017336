/* Estructura general */
.container {
  display: flex;
  height: 100vh;
  background-color: #f4f4f4;
}

.leftSide {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightSide {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  /*   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* Ajustes de texto y elementos */
.infoContainer {
  text-align: center;
}

.logo {
  max-width: 150px;
  margin-bottom: 20px;
}

.mainTitle {
  font-size: 2rem;
  color: #1c2434;
  margin-bottom: 10px;
}

.subTitle {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.benefitsList {
  list-style-type: disc;
  margin-left: 40px;
  text-align: left;
  padding: 10px;
}

.contact {
  margin-top: 20px;
  font-weight: bold;
}

.phone {
  color: #1c2434;
  text-decoration: none;
}

.promoImage {
  max-width: 100%;
  margin-top: 20px;
}

/* Formulario */
.form {
  width: 100%;
  max-width: 400px;
}

.formGroup {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitButton {
  width: 100%;
  padding: 10px;
  background-color: #1c2434;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:disabled {
  background-color: #cccccc;
}

.error {
  color: red;
  margin-top: 10px;
}

.link {
  text-align: center;
  margin-top: 15px;
}

/* Estilos responsivos */
@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    height: auto;
  }

  .leftSide,
  .rightSide {
    flex: unset;
    width: 100%;
    padding: 15px;
    box-shadow: none;
  }

  .promoImage {
    max-width: 70%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .mainTitle {
    font-size: 1.5rem;
  }

  .subTitle {
    font-size: 1rem;
  }

  .benefitsList {
    font-size: 0.9rem;
  }

  .promoImage {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .container {
    height: auto;
    padding: 10px;
  }

  .leftSide,
  .rightSide {
    padding: 10px;
  }

  .mainTitle {
    font-size: 1.3rem;
  }

  .subTitle {
    font-size: 0.9rem;
  }

  .form {
    max-width: 100%;
  }

  .promoImage {
    display: none; /* Ocultar imagen en móviles si ocupa mucho espacio */
  }
}
