/* General Styles */
.container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4; /* Color de fondo general */
}

.left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  padding: 20px;
  min-height: 100vh;
}
.left-side-2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  min-height: 100vh;
}

.right-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /*  border-radius: 8px; */
}

/* Full-screen logo (visible only on larger screens) */
.full-screen-logo {
  display: block;
  width: 200px;
}

/* Small-screen logo (visible only on smaller screens) */
.small-screen-logo {
  margin: 0 auto 20px;
  display: none;
}

/* Form Styles */
.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-button:disabled {
  background-color: #cccccc;
}

.forgot-password {
  margin-top: 10px;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .left-side {
    display: none;
  }

  .small-screen-logo {
    display: block;
    width: 50%;
  }
}

/* Section for the image */
.image-container {
  width: 50%;
  background-color: #000; /* Fondo negro */
  background-image: url("/public/img/pallevar-logo.PNG"); /* Imagen grande */
  background-size: contain; /* Mantiene la relación de aspecto sin pixelación */
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; /* Alto de la imagen */
}

/* Section for the form */
.login-container {
  width: 50%;
  padding: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #1c2434;
  text-align: center;
  margin-bottom: 15px;
  font-size: 2rem;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #1c2434;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.login-button:hover {
  background-color: #1c2434;
  opacity: 0.9;
}

.forgot-password {
  text-align: center;
  margin-top: 20px;
}

.forgot-password a {
  color: #1c2434;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

/* Responsive Styles for Image */
@media (max-width: 1024px) {
  .image-container {
    width: 100%;
    height: 50vh;
  }

  .login-container {
    width: 100%;
    padding: 20px;
    min-height: auto;
    height: auto;
  }
}

@media (max-width: 768px) {
  .image-container {
    display: none; /* Ocultar la imagen en pantallas pequeñas */
  }

  .login-container {
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    padding-top: 10px;
    height: auto;
    min-height: 100vh;
  }

  h1 {
    font-size: 1.5rem;
  }

  .login-button {
    font-size: 0.9rem;
    padding: 12px;
  }

  .small-screen-logo {
    display: block; /* Mostrar logo pequeño en pantallas pequeñas */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.25rem;
  }

  .login-button {
    font-size: 0.85rem;
    padding: 10px;
  }
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
  position: relative;

  max-height: 95vh; /* Limit the maximum height */
  overflow-y: auto; /* Allow internal scrolling */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.submit-btn {
  background-color: #0065ff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.submit-btn:hover {
  background-color: #0056d2;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.close-btn:hover {
  color: #e44db8;
}

.error-message {
  color: red;
}
