* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
}

.dashboardSection {
  margin-top: 10px;
  margin-bottom: 30px;
}

h2 {
  margin-bottom: 15px;
  color: #1c2434;
}

.cardContainer {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  background-color: #1c2434;
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 388px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1520px) {
  .card {
    max-width: 280px; /* Nuevo valor para pantallas grandes de tablets */
  }
}
@media (max-width: 768px) {
  .card {
    max-width: 250px;
  }
}

/* Para pantallas pequeñas (por ejemplo, móviles) */
@media (max-width: 480px) {
  .card {
    max-width: 200px;
  }
}

.card p {
  font-size: 0.9rem;
  margin-bottom: 8px;
}

.card h3 {
  font-size: 1.5rem;
  margin: 0;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

table thead {
  background-color: #1c2434;
  color: #ffffff;
}

table th,
table td {
  padding: 12px 15px;
  text-align: left;
}

table th {
  font-weight: bold;
}

table tr:nth-child(even) {
  background-color: #f4f4f4;
}

table tr:hover {
  background-color: #e1e1e1;
}
